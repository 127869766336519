import Backdrop from '@common_backdrop';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import { getCmsBlocks } from '@core_modules/cms/services/graphql';
import React from 'react';

const MagezonStaticBlock = (props) => {
    const { block_id, identifier, ...other } = props;

    const { data, loading } = getCmsBlocks({ identifiers: [identifier || block_id] });

    if (loading) {
        return <Backdrop open={loading} />;
    }

    if (data?.cmsBlocks?.items[0]?.content === '') {
        return null;
    }

    if (data && data.cmsBlocks && data.cmsBlocks.items && data.cmsBlocks.items.length > 0) {
        return <CmsRenderer content={data.cmsBlocks.items[0].content} {...other} />;
    }
    return null;
};

export default MagezonStaticBlock;
